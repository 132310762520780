import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import styled from "styled-components"
import { MDXRenderer } from "gatsby-plugin-mdx"

import GlobalStateProvider from "../context/provider"
import ContentWrapper from "../styles/contentWrapper"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { seoTitleSuffix } from "../../config"
import Icon from "../components/icons"

const StyledSection = styled.section`
  width: 100%;
  max-width: 62.5rem;
  margin: 0 auto;
  padding: 0 2.5rem;
  height: auto;
  background: ${({ theme }) => theme.colors.background};
  h1 {
    font-size: 1.5rem;
  }
  h2 {
    font-size: 1.25rem;
  }
  h3 {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 0;
    padding-left: 0;
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      padding-right: 2.5rem;
      padding-left: 2.5rem;
    }
    .section-title {
      padding-right: 2.5rem;
      padding-left: 2.5rem;
      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        padding-right: 1rem;
        padding-left: 1rem;
      }
    }
    .bn-node-wrapper {
      display: block;
      width: 100%;
      border-bottom: solid 1px;
      padding-bottom: 1.25rem;
      margin-top: 1.25rem;
      &:last-child {
        border-bottom: none;
      }
    .bn-node {
      display: block;
      padding: 0.5rem;
      filter: grayscale(20%) contrast(1) brightness(90%);
      transition: all 0.3s ease-out;
      &:hover {
        background: ${({ theme }) => theme.colors.tertiary};
      }
    }
    h4 {
      margin-left: 1.5rem;
    }
    .bn-title {
    }
    .bn-date {
      font-size: 1.375rem;
    }
  }
`

const Backnumbers = ({ data }) => {
  const backnumbers = data.backnumbers

  const globalState = {
    isIntroDone: true,
    darkMode: false,
  }

  return (
    <GlobalStateProvider initialState={globalState}>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/yakuhanjp@3.4.1/dist/css/yakuhanjp.min.css"
        />
      </Helmet>
      <Layout>
        <SEO
          title="バックナンバー"
          meta={[{ name: "robots", content: "noindex" }]}
        />
        <StyledSection id="backnumbers">
          <StyledContentWrapper>
            <h1 data-testid="heading">バックナンバー</h1>
            <div className="backnumbers">
              {backnumbers.edges.map((backnumber, key) => {
                const { body, frontmatter } = backnumber.node
                return (
                  <div className="bn-node-wrapper" key={key}>
                    <a
                      className="bn-node"
                      href={`/${frontmatter.file}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <h4>
                        <div className="bn-title">
                          <Icon name="newspaper" color="black" />{" "}
                          {frontmatter.title}
                        </div>
                        <div className="bn-date">{frontmatter.date}</div>
                      </h4>
                      <div className="bn-body">
                        <MDXRenderer>{body}</MDXRenderer>
                      </div>
                    </a>
                  </div>
                )
              })}
            </div>
          </StyledContentWrapper>
        </StyledSection>
      </Layout>
    </GlobalStateProvider>
  )
}

Backnumbers.propTypes = {
  data: PropTypes.shape({
    backnumbers: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.object.isRequired,
            body: PropTypes.string.isRequired,
          }).isRequired,
        }).isRequired
      ).isRequired,
    }).isRequired,
  }).isRequired,
}

export default Backnumbers

export const pageQuery = graphql`
  {
    backnumbers: allMdx(
      filter: { fileAbsolutePath: { regex: "/backnumbers/" } }
      sort: { fields: [frontmatter___file], order: DESC }
    ) {
      edges {
        node {
          body
          frontmatter {
            title
            date
            file
          }
        }
      }
    }
  }
`
